export default {
  /**
   * 手机号码
   * @param phoneNumber
   * @constructor
   */
  RegPhone(phoneNumber) {
    const reg = /^1(3|4|5|7|8)\d{9}$/;
    return !reg.test(phoneNumber);
  },

  /**
   * 邮箱
   * @param emailString
   * @constructor
   */
  RegEmail(emailString) {
    const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    return !reg.test(emailString);
  },

  /**
   * 密码正则表达式
   * @param password
   * @constructor
   */
  RegPw(password) {
    const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&*()_+|{}:"?]{8,16}$/;
    return !reg.test(password);
  },
};
