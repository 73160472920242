import Alert from '@/components/common/Alert/Alert.vue';
import { createApp } from 'vue';

export default ({ title, content, okText = '确认', okEvent }) => {
  const mountNode = document.createElement('div');
  const instance = createApp(Alert, {
    title,
    content,
    okText,
    okEvent,
  });
  document.body.appendChild(mountNode);
  instance.mount(mountNode).openAlert();
};
