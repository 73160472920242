<template>
  <div v-for="(item, index) in audioSrcList" :key="index">
    <svgIcon :id="id + index + 'svg'" class-name="html_megaphone_icon" icon-class="megaphone" style="display: block" @click="playAudioEvent(index)"></svgIcon>
    <img :id="id + index + 'img'" class="html_megaphone_icon" src="@/assets/images/audioPlay.gif" style="display: none" />
    <audio :id="id + index" :src="item" :volume="1"></audio>
  </div>
</template>

<script setup>
import { nextTick, onMounted } from 'vue';

const $props = defineProps({
  id: String,
  audioSrcList: {
    type: Array,
    default: [],
  },
});
const playAudioEvent = async (index) => {
  await document.getElementById($props.id + index).load();
  await document.getElementById($props.id + index).play();
};
onMounted(() => {
  nextTick(() => {
    $props.audioSrcList.forEach((item, index) => {
      document.getElementById($props.id + index).addEventListener('playing', () => {
        document.getElementById($props.id + index + 'img').style.display = 'block';
        document.getElementById($props.id + index + 'svg').style.display = 'none';
      });
      document.getElementById($props.id + index).addEventListener('pause', () => {
        document.getElementById($props.id + index + 'img').style.display = 'none';
        document.getElementById($props.id + index + 'svg').style.display = 'block';
      });
    });
  });
});
</script>

<style lang="less" scoped>
.html_megaphone_icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;
}
</style>
