<template>
  <svg aria-hidden="true" :class="svgClass">
    <use :xlink:href="iconName" :fill="color" />
  </svg>
</template>

<script setup>
import { computed } from "vue";
const $props = defineProps({
  iconClass: {
    type: String,
    required: true,
  },
  className: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "",
  },
});

const iconName = computed(() => `#icon-${$props.iconClass}`);
const svgClass = computed(() => {
  if ($props.className) {
    return `${$props.className} svg-icon`;
  } else {
    return `svg-icon`;
  }
});
</script>

<style scoped lang="scss">
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 12px !important;
}
</style>
