if (import.meta.env.VITE_APP_ENV != 'development') {
  //禁止快捷键打开控制台
  window.oncontextmenu = function () {
    return false;
  };
  document.onkeydown = () => {
    //禁用F12
    if (window.event && window.event.keyCode == 123) {
      return false;
      //禁用ctrl+shift+i,
    } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
      return false;
      //屏蔽Shift+F10
    } else if (window.event.shiftKey && window.event.keyCode == 121) {
      return false;
    }
  };

  //禁止右击菜单
  document.oncontextmenu = function () {
    return false;
  };

  //禁止图片拖动
  document.body.onselectstart = document.body.ondragstart = function (e) {
    e.preventDefault();
    return false;
  };
}
