import { createApp } from 'vue';

import 'amfe-flexible';
import App from './App.vue';
import router from './router';
import '@/assets/css/theme.less';
import '@/assets/css/mian.less';
import '@/assets/css/anime.scss';
import '@/assets/font/iconfont/iconfont.css';
import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';
import '@/plugins/disabledConsole.js';
import i18n from '@/locales/index.js';
import svgIcon from '@/components/svgIcon/index.vue';
import 'virtual:svg-icons-register';
import pinia from '@/stores/index.js';
import { richText, richTextSrc } from '@/directive/richText.js';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { createHead } from '@unhead/vue';
import Empty from '@/components/empty/index.vue';
import Confirm from '@/components/common/Confirm/Confirm.vue';
import PDFObjectPlugin from 'pdfobject-vue';

const head = createHead();
import('@/common/NProgress.js');

// (function () {
//   var checkStatus;
//   var element = new Image();
//   element.__defineGetter__('id', function () {
//     checkStatus = 'on';
//   });
//   setInterval(function () {
//     checkStatus = 'off';
//     console.log(element);
//     if (checkStatus === 'on') {
//       document.onkeydown = null;
//       document.oncontextmenu = null;
//     }
//   }, 1000);
// })();

const app = createApp(App);
app.use(pinia);
app.use(i18n);
app.use(router);
app.use(VueAnimXyz);
app.use(head);
app.use(VueDOMPurifyHTML);
app.use(PDFObjectPlugin);
app.component('SvgIcon', svgIcon);
app.component('Empty', Empty);
app.component('Confirm', Confirm);
app.mount('#app');

app.directive('richText', richText);
app.directive('richTextSrc', richTextSrc);
