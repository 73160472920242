<!--alert-->
<template>
  <a-modal
    v-model:visible="visible"
    :esc-to-close="false"
    :hide-cancel="true"
    :mask="mask"
    :mask-closable="false"
    :ok-text="okText"
    :on-ok="okEvent"
    modal-class="modal_alert"
    @cancel="handleCancel"
    @ok="okEvent"
  >
    <template #title>
      {{ title }}
    </template>
    <div class="alert_content">{{ content }}</div>
  </a-modal>
</template>
<script setup>
import { ref } from 'vue';
import { Modal as AModal } from '@arco-design/web-vue';

const $props = defineProps({
  title: String,
  mask: {
    type: Boolean,
    default: true,
  },
  content: String,
  okText: String,
  okEvent: Function,
});

const visible = ref(false);

const handleCancel = () => {};

const openAlert = () => {
  visible.value = true;
};

defineExpose({
  openAlert,
});
</script>

<style lang="less">
.modal_alert {
  width: 520px;
  min-height: 210px;
  background: #ffffff;
  border-radius: 8px;

  .arco-modal-header {
    border-radius: 8px 8px 0 0;
    height: 50px;
    background: #f5f9fd;
    border: 0;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    text-align: left;
    font-style: normal;
  }

  .arco-modal-body {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    min-height: 50px;

    .alert_content {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: center;
      font-style: normal;
    }
  }

  .arco-modal-footer {
    border: 0;
    text-align: center;

    .arco-btn {
      margin: 0 auto;
      width: 150px;
      height: 36px;
      background: #0678ff;
      border-radius: 19px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 20px;
      text-align: center;
      font-style: normal;
    }
  }
}
</style>
