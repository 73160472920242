import FileSaver from 'file-saver';

/**
 * 下载文件
 * @param url
 * @param filename
 */
export function useDownloadFile(url, filename = '') {
  FileSaver.saveAs(url, filename);
}

/**
 * 更新视频源
 * @param player
 * @param newSrc
 */
export function useUpdateVideoSource(player, newSrc) {
  player.src({
    src: newSrc,
    type: 'video/mp4', // 或者其他视频格式
    provider: 'video',
  });
  // 播放新的视频
  player.load();
  player.play();
}

/**
 * 解析富文本
 * @param html
 * @returns {*}
 */
export function useAnalyzeHtml(html) {
  return html;
}

/**
 * 处理音频
 * @param el
 */
export function useSelectAudioHtml(el) {}

/**
 * 动态获取资源
 * @param url
 * @returns {string}
 */
export function useGetAssetsFile(url) {
  return new URL(url, import.meta.url).href;
}

export function useGetEnv(key) {
  return import.meta.env[key];
}
