import axios from 'axios';
import { Notification } from '@arco-design/web-vue';
import { use$t } from '@/hooks/language.js';
import i18n from '@/locales/index.js';
import { langToBack } from '@/hooks/sysDict.js';
import { hideLoadingProcess, useToHome } from '@/hooks/page.js';
import { useLocalCacheStore } from '@/stores/localCache.js';
import { useCheckIsLogin } from '@/hooks/commomApi.js';

// 创建axios实例
const service = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 10000,
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (!config.hasOwnProperty('params') || !config.params.hasOwnProperty('lang')) {
      config.url = config.url + '?lang=' + langToBack[i18n.global.locale.value];
    }
    // 可以在这里添加请求头等信息
    let xAuthToken = localStorage.getItem('Token');
    if (xAuthToken) {
      config.headers['Authorization'] = 'Bearer ' + xAuthToken;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做处理，例如只返回data部分
    if (response.data.Status) {
      return response.data;
    } else {
      hideLoadingProcess();
      if (response.data.code == 1021) {
        Notification.warning({
          content: use$t('individual.center.wart'),
        });
      } else if (response.data.code != 1023) {
        Notification.error(use$t('code.' + response.data.code));
        return Promise.reject(response.data);
      }
    }
  },
  (error) => {
    let localCache = useLocalCacheStore();
    // 响应错误处理
    if (error.hasOwnProperty('response') && error.response.status === 401) {
      localCache.setUserInfo(undefined);
      localStorage.removeItem('Token');
      if (error.config.url.indexOf('/Course/IsActivation') === -1) {
        useCheckIsLogin(() => {}, true);
      }
    }
    return Promise.reject(error);
  }
);

// 导出axios实例
export default service;
