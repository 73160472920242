<!--意见反馈-->
<template>
  <a-modal
    v-model:visible="feedbackVisible"
    :cancel-text="use$t('feedback.btn.cancel')"
    :esc-to-close="false"
    :lang="i18n.locale.value"
    :mask-closable="false"
    :ok-text="use$t('practiceResult.submit.now')"
    :on-before-ok="handleOk"
    :title="use$t('footer.problem.feedback')"
    :unmount-on-close="true"
    modal-class="feedback_modal"
    @cancel="handleCancel"
    @before-open="beforeOpen"
  >
    <!--    <div class="feedback_group">
          <a-button>{{ use$t('feedback.btn.proposal') }}</a-button>
          <a-button>{{ use$t('feedback.btn.defect') }}</a-button>
          <a-button>{{ use$t('feedback.btn.want') }}</a-button>
        </div>-->
    <div class="feedback_tips">
      <div class="feedback_text">{{ use$t('feedback.text') }}</div>
      <a-image :preview="false" :src="feedbackIcon"></a-image>
    </div>
    <div class="feedback_form">
      <p>{{ use$t('feedback.problem') }}</p>
      <a-textarea
        v-model="queryParams.content"
        :auto-size="{ minRows: 6, maxRows: 6 }"
        :max-length="500"
        :placeholder="use$t('feedback.problem.placeholder')"
        show-word-limit
        @blur="changeEvent"
        @change="changeEvent"
      ></a-textarea>
      <div class="arco-form-item-message" role="alert">{{ queryParams.error }}</div>
    </div>
  </a-modal>
</template>

<script setup>
import { computed, reactive } from 'vue';
import { use$t } from '@/hooks/language.js';
import feedbackIcon from '@/assets/images/feedback_icon.gif';
import request from '@/common/request.js';
import { cloneDeep } from 'lodash';
import { Notification } from '@arco-design/web-vue';
import { useI18n } from 'vue-i18n';

const $props = defineProps({
  feedbackVisible: {
    type: Boolean,
    default: false,
  },
});

const i18n = useI18n();
const $emits = defineEmits(['update:feedbackVisible']);

const queryParams = reactive({
  content: '',
  error: '',
});

const copyQueryParams = reactive(cloneDeep(queryParams));

const feedbackVisible = computed({
  get: () => {
    return $props.feedbackVisible;
  },
  set: (val) => {
    $emits('update:feedbackVisible', val);
  },
});

const handleCancel = () => {
  feedbackVisible.value = false;
};

const changeEvent = () => {
  if (queryParams.content != '') {
    queryParams.error = '';
  } else {
    queryParams.error = use$t('feedback.problem.placeholder');
  }
};
const handleOk = async () => {
  if (queryParams.content === '') {
    queryParams.error = use$t('feedback.problem.placeholder');
    return false;
  }
  let axiosResponse = await request({
    url: '/ProblemBack/AddProblemBack',
    method: 'post',
    params: {
      content: queryParams.content,
    },
  });
  if (axiosResponse.code != '1017') {
    return false;
  } else {
    Notification.success(use$t('code.1017'));
  }

  return true;
};

const beforeOpen = () => {
  Object.assign(queryParams, copyQueryParams);
};
</script>

<style lang="less">
.feedback_modal {
  width: 610px;
  border-radius: 8px;

  .arco-modal-header {
    border-bottom: 0;
    height: 50px;
    background-image: url('@/assets/images/modal_title.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .arco-modal-title {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
      text-align: center;
      font-style: normal;
    }

    .arco-icon-close {
      display: none;
    }
  }

  .arco-modal-body {
    padding-top: 10px;
    padding-bottom: 0;

    .feedback_group {
      .arco-btn {
        height: 34px;
        background: #efeff0;
        border-radius: 2px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .feedback_tips {
      height: 73px;
      border-radius: 4px 0 0 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .feedback_text {
        font-family: PingFang SC;
        background: #e8f1fe;
        width: 85%;
        height: 34px;
        font-weight: 400;
        font-size: 14px;
        padding-left: 10px;
        color: #575d6c;
        line-height: 34px;
        font-style: normal;
      }

      .arco-image-img {
        width: 73px;
        height: 73px;
      }
    }

    .feedback_form {
      p {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }

      .arco-textarea-wrapper {
        margin-top: 12px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d6d6d6;
      }
    }
  }

  .arco-modal-footer {
    border-top: 0;
    padding-bottom: 36px;

    .arco-btn {
      &:first-child {
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        text-align: center;
        font-style: normal;
      }

      &:last-child {
        width: 120px;
        height: 36px;
        background: #0678ff;
        border-radius: 4px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        text-align: center;
        font-style: normal;
      }
    }
  }

  &:lang(ar) {
    .feedback_text {
      text-align: right;
      padding-right: 10px;
    }

    .feedback_form {
      p {
        text-align: right;
      }
    }

    .arco-modal-footer {
      .arco-btn {
        &:first-child {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
